.main {
  position: absolute;
  /* border: 2px dotted blue; */
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  /* border: 2px dotted red; */
  /* height: 120px;
  width: 80px;
  border-radius: 20px; */

}