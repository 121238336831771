body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.Home-main-wrapper {
  position: absolute;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: 'column';
  justify-content: 'space-between';
  align-items: 'center';

}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.Home-background {
  /* background-image: url("./images/photo-tropical-island.jpg"); */
  background-color: #525252;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1000;
  opacity: 1;
}

.Home-nav-bottom {
  width: 100%;
  height: 56px;
  border-radius: 28px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.Home-subpages-wrapper {
  position: relative;
  border-radius: 30px;
}

.border-red {
  border: 1px solid red;
}


.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #ff9933;
  border-right: 8px solid #ff9933;
  border-bottom: 8px solid #ff9933;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Home-admin-menu p{
  margin-bottom: 15px;
  padding: 0;
  cursor: pointer;
  font-size: 110%;
  font-weight: 600;
  color: "#444444"
}

input {
  border: 'none'
}

.borderRed {
  border: '1px solid red';
}

.make-absolute {
  position: absolute;
  z-index: 100;
}