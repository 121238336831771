.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}


.Phases-description-block {
  background-color: '#f6f6f6';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 30px 20px 30px;
  font-weight: 400;
  font-size: 110%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Phases-description-block h3{
  margin-bottom: -12px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Phases-content-block {
  padding: 30px 30px 5px 30px;
  font-weight: 500;
  font-size: 110%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Phases-trait-list {
  background-color: #cccccc;
  margin: 0 -40px;
  padding: 0 40px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2) inset;
  font-weight: 500;
  color: #555555;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Phases-slidein-page {
  /* box-shadow: -5px 0px 5px rgba(0,0,0,0.); */
}

.Phases {
  padding: 40px 25px;
}
.Phases h3 {
  opacity: 0.8;
  margin-bottom: 35px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Phases Subpages */

.Phases-subpage-header {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 30px 5px 25px;
  background-color: #e6e6e6;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Phase-subpage-header-icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
}
.Phase-subpage-header-text {
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  font-weight: 600;
  font-size: 130%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Plan Steps */ 

.Plan-step-wrapper {
  display: flex;
  justify-content: space-between; 
  margin-bottom: 40px;
  cursor: pointer;
}

.Plan-step-text {
  width: 88%;
  font-weight: 500;
  font-size: 130%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Plan-step-icon {
  display: flex;
  align-items: center;
}

/* Edit Resource List */
.Plan-resource-list-field {
  width: 100%;
  height: 35px;
  padding: 8px 8px;
  margin-bottom: 8px;
  font-size: 110%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}