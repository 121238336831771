.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.Resources-spacer {
  margin: 0 0px 0 10px;
}

.Resources-outer-bar {
  width: 100px;
  height: 9px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.25);
  background-color: rgba(0,0,0,0.11);
}

.Resources-inner-bar {
  height: 100%;
  background-color: #F8C115;
  /* background-image: linear-gradient(to right, #ff73bd , #ff9933); */
  border-radius: 3px;
  width: 50%;
}

.Resources-value {
  color: rgba(0,0,0,0.45);
  font-size: 90%;
}

.Resources-heading {
  margin: 20px 0 5px 3px;
  font-size: 120%;
  font-weight: 600;
  font-family: 'Big Shoulders Display', cursive;
  opacity: 1;
  text-align: left;
  width: 100%;
  /* text-decoration: underline; */
}