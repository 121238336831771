/* ViewAssessments-bar-label {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
} */

.ViewAssessments-outer-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 11px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 0;
  padding: 1px;
  box-shadow: -1px -1px 1px rgba(255,255,255,0.6) inset, 2px 2px 3px rgba(0,0,0,0.14) inset;

  background-color: #dfdfdf;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.ViewAssessments-inner-bar {
  width: 100%;
  /* margin: 0 0 0 -5px; */
  height: 100%;
  border-radius: 4px;
  height: 7px;
  margin-left: 2px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.ViewAssessments-inner-bar-gold {
  background-image: linear-gradient(to right, #F5B01B , #F8C115);
}

.ViewAssessments-inner-bar-gray {
  background-image: linear-gradient(to right, #808080 , #B3B3B3);
}

.ViewAssessments-inner-bar-dark {
  background-image: linear-gradient(to right, #40b0eb , #0cceeb);
}

.ViewAssessments {

}

.ViewAssessments.big-shoulders-display {
  font-family: 'Big Shoulders Display', cursive;
}